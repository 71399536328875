<script lang="ts" setup>
import { ref, resolveDynamicComponent } from "vue";

const props = defineProps({
  tabs: {
    type: Array as PropType<Tabs>,
    required: true,
  },
  modelValue: {
    type: String,
  },
});

const emit = defineEmits(["update:modelValue"]);

const selectTab = (key: string) => {
  emit("update:modelValue", key);
};
</script>

<template>
  <div class="flex flex-row rounded border-2 bg-surface">
    <span
      v-for="tab in tabs"
      class="grow text-center cursor-pointer hover:bg-light-default p-2"
      @click="selectTab(tab.key)"
      :class="{
        'bg-light-shade': modelValue == tab.key,
      }"
    >
      {{ tab.label }}
    </span>
  </div>
</template>

<style scoped></style>
